import axios from 'axios';
import url from './url';

const Api = {
    getUsuario : async(usuario, contrasenia)=>{
        var data = {
            'usuario':usuario, 
            'contrasenia':contrasenia,
        }
        return await axios.post(url.url+'/acceso', data, 
        {headers: {
                    'Content-Type': 'application/json'                       
                }
        })
    },
}


export default Api