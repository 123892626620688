import axios from 'axios';
import url from './url';

const Api = {
    getCodigoQR: async (codigo) => {   
        var urlMe = url.url + '/' + codigo;
        var opts = {
            method :'GET',
            mode : 'cors',
            cache : 'default', 
            headers :{
                'Content-Type': 'application/json'
            }
        }
        return await axios.get(urlMe, opts)
    },
    getValorQR: async (codigo) => {   
        var urlMe = url.url + '/valorQR/' + codigo;
        var opts = {
            method :'GET',
            mode : 'cors',
            cache : 'default', 
            headers :{
                'Content-Type': 'application/json'
                ,'Authorization': localStorage.getItem("Authorization")
            }
        }
        return await axios.get(urlMe, opts)
    },
    postRegistroQR: async(codigo, descripcion, urlweb, valor,tipo, valora, valorb, valorg, valorr, usuario)=>{
        var data = {
            'codigo': codigo,
            'descripcion': descripcion,
            'urlweb': urlweb, 
            'valor': valor, 
            'tipo':tipo,
            'valora':valora, 
            'valorb':valorb, 
            'valorg':valorg, 
            'valorr':valorr, 
            'usuario':usuario
        }
        return await axios.post(url.url+'/registoQR',
            data,
            {headers: {
                        'Content-Type': 'application/json'                       
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    postAgregarImg: async(idImg, imagen, descripcion, notas)=>{
        var data = {
            'idImg': idImg,
            'imagen': imagen,
            'descripcion': descripcion,
            'notas': notas
        }
        return await axios.post(url.url+'/agregarImg',
            data,
            {headers: {
                        'Content-Type': 'application/json'                     
                        ,'Authorization': localStorage.getItem("Authorization")  
                    }
            }
        ) 
    },
    postUploadImg: async(formData)=>{
        var data = {
            'formData ': formData
        }
        return await axios.post(url.url+'/uploadImg',
            data,
            {headers: {
                        'Content-Type': 'multipart/form-data'     
                        ,'Authorization': localStorage.getItem("Authorization")                  
                    }
            }
        ) 
    },
    postUploadImgAPI: async(imagen)=>{
        var data = {
            'objfile ': imagen
        }
        return await axios.post('https://52.177.189.223:3030/ImageUpload/api/ImageUpload/',
            data,
            {headers: {
                        'Content-Type': 'multipart/form-data', 
                        'Access-Control-Allow-Origin': '*'
                    }
            }
        ) 
    }, 
    putActualizarQR: async(codigo, descripcion, pagina, tipo, valora, valorb, valorg, valorr, usuario)=>{
        var data = {
            'descripcion': descripcion, 
            'url': pagina, 
            'tipo':tipo,
            'valora':valora, 
            'valorb':valorb, 
            'valorg':valorg, 
            'valorr':valorr,
            'usuario':usuario
        }
        return await axios.put(url.url+'/actualizarQR/'+codigo, 
        data, 
        {headers: {
            'Content-Type': 'application/json'                 
            ,'Authorization': localStorage.getItem("Authorization")      
        }
        })
    }
}
export default Api