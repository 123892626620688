import React, {Component, Fragment } from 'react';
import jwt from 'jwt-decode';
import QRCode from 'qrcode.react';
import {MDBContainer, MDBRow, MDBCol, MDBInput} from "mdbreact";
import {MDBBtn, MDBIcon, MDBAlert} from "mdbreact";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCardText} from 'mdbreact';
import { CompactPicker } from 'react-color';
import reactCSS from 'reactcss';
import ControllerQR from '../../controllers/CodigoQR';
import axios from 'axios';
import SpinnerPage from '../Spinner/';
import url from '../../api/url';



class CargarQR extends Component{

    constructor(props){
        super(props);
        this.state={
            usuario:0,
            qr:false,
            urlcodigo:'',
            val: 0,
            codigo:'',
            descripcion:'', 
            pagina:'',
            idQR:0,
            archivo: '',
            selectFile:null,
            descImg:'',
            notaImg:'',
            imgQR:false,
            disableQR:false,
            msjQR:'',
            lstImagenes:[],
            statusMod: false,
            spinner : true,
            tipo:"0",
            displayColorPicker: false,
            color:'',
            background: {
                r: '241',
                g: '112',
                b: '19',
                a: '1'},
        }
        this.createQrCode= this.createQrCode.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.changeHandlerTipo = this.changeHandlerTipo.bind(this);
        this.changeHandlerImg = this.changeHandlerImg.bind(this);
        this.AgregarImg = this.AgregarImg.bind(this);
        this.Limpiar = this.Limpiar.bind(this);
        this.Modificar = this.Modificar.bind(this);this.closeSession = this.closeSession.bind(this);
    };

    componentDidMount(){
        const key = localStorage.getItem("Authorization")
        if(key === null){
            window.localStorage.clear()
            window.location = '/'
        }else{
            const jwtkey = jwt(key)
            this.setState({usuario:jwtkey.user.id})
            var dateNow = new Date()
            if((dateNow.getTime()/1000) > jwtkey.exp){
                window.localStorage.clear()
                window.location = '/'
            }
        }
    }

    closeSession =() => () =>{window.localStorage.clear(); window.location = '/';}

    onClick = (nr) => () => {this.setState({check: nr});}

    createQrCode(e)
    {
        if (e.target.value.length >= 5){
            var rand;
            const tvalue= e.target.value;
            let backgroundVar = Object.assign({}, this.state.background);
            backgroundVar.a ='1'
            backgroundVar.b ='19'
            backgroundVar.g ='112'
            backgroundVar.r ='241'
            ControllerQR.fechDataValorQR(e.target.value)
            .then(res=>{
                if(res.data.success){
                    if(res.data.data.valor.length > 0){
                        rand= res.data.data.valor[0].Valor
                        var lstImgM=[];
                        backgroundVar.a =res.data.data.valor[0].ValorA
                        backgroundVar.b =res.data.data.valor[0].ValorB
                        backgroundVar.g =res.data.data.valor[0].ValorG
                        backgroundVar.r =res.data.data.valor[0].ValorR
                        res.data.data.valor.forEach(img =>{lstImgM.push(img.Img)})
                        this.setState({idQR:res.data.data.valor[0].Idcodigo,msjQR:'Código cargado previaente!!', color:'warning',descripcion:res.data.data.valor[0].Descripcion, pagina:res.data.data.valor[0].Url, tipo:res.data.data.valor[0].Tipo, lstImagenes:lstImgM, statusMod:true,spinner:false,background:backgroundVar})
                    }else{
                        rand =  Math.floor((1 + (Math.random()*99)));
                        this.setState({msjQR:'', color:'',descripcion:'', pagina:'', lstImagenes:[], statusMod:false,spinner:false,idQR:0,tipo:"0", background:backgroundVar})
                    }
                }
                else{
                    rand =  Math.floor((1 + (Math.random()*99)));
                    this.setState({msjQR:'', color:'',descripcion:'', pagina:'', lstImagenes:[], statusMod:false,spinner:false,idQR:0,tipo:"0", background:backgroundVar})
                }
            })
            .then(res=>{
                const val = url.urlF+'/'+ tvalue +'.'+ rand;
                this.setState({qr:true, urlcodigo:val, codigo:tvalue, val:rand,spinner:false, background:backgroundVar})
            }).catch(err=>{this.setState({idQR:0,spinner:true})})
        } 
        else{
            this.setState({qr:false, urlcodigo:'', codigo:'', statusMod:false,spinner:true})
        }       
    };

    Limpiar = event =>{document.forms[0].submit();}

    Modificar= event =>{
        if(this.state.codigo.length>=5 && this.state.codigo && this.state.tipo !=="0") // && this.state.descripcion && this.state.pagina
        {
            const data = ControllerQR.fetchDataActualizarQR(this.state.codigo, this.state.descripcion, this.state.pagina,this.state.tipo, this.state.background.a, this.state.background.b, this.state.background.g, this.state.background.r, this.state.usuario)
            data.then(res=>{
                if(res.data.success){
                    if(res.data.data.idCodigo[0].IDQR !== 0){
                        this.setState({idQR:0, msjQR:'Código modificado exitosamente', color:'primary', descripcion:"", codigo:"", lstImagenes:[], pagina:"",tipo:0,statusMod:false, background:''})
                    }
                }
            }).catch(err=>{this.setState({msjQR: '', tipo:"0"})})
        }
    }

    changeHandler = event => {this.setState({ [event.target.name]: event.target.value });};

    changeHandlerTipo = event =>  {this.setState({ [event.target.name]: event.target.value});if(event.target.value!=="0"){this.setState({msjQR:"" });}};

    changeHandlerImg = event => {
        this.setState({archivo: event.target.files[0].name, selectFile:event.target.files[0]})
    };
    
    handleClick = () => {this.setState({ displayColorPicker: !this.state.displayColorPicker })};
    
    handleClose = () => {this.setState({ displayColorPicker: false })};
    
    handleChange = (background) => {
        this.setState({ background: background.rgb });    
        document.body.style.background =`rgba(${ this.state.background.r}, ${ this.state.background.g }, ${ this.state.background.b }, ${ this.state.background.a })`;
    };

    submitHandler = event => {
        event.preventDefault();
        if(this.state.tipo ==="0"){this.setState({msjQR:'Debes seleccionar un tipo de vsita!',color:'success'})}
        else{
            if(this.state.codigo.length>=5 && this.state.codigo && this.state.tipo !==0)
            {
                event.target.className += "was-validated disabled";
                if(this.state.codigo){
                    const data = ControllerQR.fetchDataRegistrarQR(this.state.codigo, this.state.descripcion, this.state.pagina, this.state.val, this.state.tipo, this.state.background.a, this.state.background.b, this.state.background.g, this.state.background.r, this.state.usuario)
                    data.then(res=>{
                        if(res.data.success){
                            if(res.data.data.idQR[0].IDQR>0){this.setState({idQR:res.data.data.idQR[0].IDQR, imgQR:true, msjQR:'Código cargado con éxito', color:'primary'})}
                            else{this.setState({idQR:res.data.data.idQR[0].IDQR, msjQR:'El código ya fue cargado', color:'warning'}) }
                        }else{this.setState({idQR:0, imgQR:false, msjQR:'',tipo:"0"})}
                    }).catch(err=>{
                        this.setState({msjQR: '',tipo:"0"});
                    })
                }
            }
        }
    };

    AgregarImg(){
        if(this.state.idQR >0){
            const file = this.state.selectFile;
             let fd = new FormData();
             fd.append('objfile', file)
             fd.append('url', "UploadNN");
                axios({
                url: 'https://zermatinternal.com:3030/ImageUpload/api/ImageUpload/?tipo=1', 
                method:'POST',
                headers:{'Content-Type': 'multipart/form-data'}, 
                data: fd
            }).then(res=>{
                const dataImg = ControllerQR.fetchDataAgregarImg(this.state.idQR, this.state.archivo, this.state.descImg, this.state.notaImg)
                dataImg.then(res=>{
                let arrImg = this.state.lstImagenes
                arrImg.push(this.state.archivo)
                this.setState({lstImagenes:arrImg, archivo:'',selectFile:null, descImg:'', notaImg:'',});
            })  
            }).catch(err=>{
                
            })
        }
    }   


    render(){
        const styles = reactCSS({
            'default': {
            colorSt: {
                width: '55px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${ this.state.background.r}, ${ this.state.background.g }, ${ this.state.background.b }, ${ this.state.background.a })`,
              },
              swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
              },
              popover: {
                position: 'absolute',
                zIndex: '2',
              },
              cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
              },
            },
          });
        return(
            <MDBContainer className="fluid">
                <div className="text-center text-white py-1 px-2 z-depth-4">
                    <h1 className="white-text mb-5 mt-4 font-weight-bold">
                    <strong>Generador </strong>
                    <a href='#!' className='blue-text font-weight-bold'>
                        <strong> QR </strong>
                    </a>
                    <strong> Nuevos Negocios </strong>
                    </h1>                   
                </div>
                <br/>
                <MDBRow>
                    <MDBCol sm="10">
                        {this.state.msjQR !== '' && <MDBAlert sm="10" color={this.state.color} >{this.state.msjQR}</MDBAlert>}
                        {this.state.idQR <0 &&<MDBBtn color="default" onClick={this.Limpiar}>
                                Limpiar<MDBIcon icon="broom" className="ml-1" />
                        </MDBBtn>}
                        {this.state.codigo && this.state.idQR<=0 && this.state.spinner==="true" && <SpinnerPage/>}
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol sm="10">
                        {this.state.qr && 
                        <MDBCard color="elegant-color">
                            <MDBCardBody>
                                <QRCode
                                    id= {this.state.codigo}
                                    value={this.state.urlcodigo}
                                    size={300}
                                    level={"L"}
                                    includeMargin={true}
                                    bgColor = "#FFFFFF"
                                    fgColor= "#000000"
                                />
                            </MDBCardBody>
                        </MDBCard>}
                    </MDBCol>
                </MDBRow>
                <form className="needs-validation" onSubmit={this.submitHandler}>
                <MDBRow md="1">
                    <MDBCol sm="10">
                        <div className="grey-text">
                            <MDBInput label="Codigo" icon="barcode" group type="text" validate error="wrong" success="right" name="codigo" required className="white-text" onChange={this.createQrCode}/>
                        </div>
                        </MDBCol>
                </MDBRow>
                <MDBRow md="1">
                    <MDBCol sm="10">
                            <div className="grey-text">
                                <MDBInput label="Título" icon="align-justify" group type="text" validate error="wrong" success="right" name="descripcion" id="descripcion" className="white-text" value={this.state.descripcion} onChange={this.changeHandler} />
                            </div>
                        </MDBCol>
                </MDBRow>
                <MDBRow md="1">
                    <MDBCol sm="10">
                        <div className="grey-text">
                            <MDBInput label="Sitio web" icon="globe-americas" group type="text" validate error="wrong" success="right" name="pagina" className="white-text" onChange={this.changeHandler} value={this.state.pagina}/>
                        </div>
                        </MDBCol>
                </MDBRow>
                <MDBRow md="1">
                    <MDBCol sm="1">
                            <MDBIcon icon="glasses" className="grey-text"/>
                        </MDBCol>
                    <MDBCol md="4">
                            <select className="browser-default custom-select" name="tipo" required onChangeCapture={this.changeHandlerTipo} validate="true" value={this.state.tipo}>
                                <option value="0">Selecciona una opción</option>
                                <option value="1">Vista Carrusel</option>
                                <option value="2">Vista Slider</option>
                            </select>
                        </MDBCol>
                </MDBRow>
                <br/>
                <MDBRow className="mt-2 mb-13 d-flex justify-content-center">
                    <MDBCol sm="10">
                                <div>
                                    <div style={ styles.swatch } onClick={ this.handleClick }>
                                    <div style={ styles.colorSt } />
                                    </div>
                                    { this.state.displayColorPicker ? <div style={ styles.popover }>
                                    <div style={ styles.cover } onClick={ this.handleClose }/>
                                    <CompactPicker color={ this.state.background } onChange={ this.handleChange } />
                                    </div> : null }
                                </div>
                        </MDBCol>
                </MDBRow>
                <MDBRow className="mt-2 mb-13 d-flex justify-content-center">
                    <MDBCol>
                        <div className="text-center white-text">
                            {this.state.msjQR === '' && this.state.idQR ===0 && <MDBBtn  color="mdb-color" rounded type="submit">
                                <MDBIcon icon="save" className="mr-1"/> Enviar
                                </MDBBtn>
                            }
                        </div>  
                    </MDBCol>
                </MDBRow>
                <div className="appBarSpacers"/>
                <div className="appBarSpacers"/>
                </form>
                <MDBRow>
                    <MDBCol sm="10">
                        <br/>
                            {this.state.imgQR &&
                            <div className="custom-file"> 
                                <input type="file" className="custom-file-input" name="archivo" aria-describedby="inputGroupFileAddon01" onChange={this.changeHandlerImg} accept ="image/*"/>
                                <label className="custom-file-label" htmlFor="inputGroupFile01">{this.state.archivo}</label>
                                {this.state.archivo && <Fragment>
                                    <MDBInput label="Comentarios Imagen" icon="align-justify" group type="text" validate error="wrong" success="right" name="descImg" className="white-text" onChange={this.changeHandler}/>
                                    <MDBBtn color="primary" onClick={this.AgregarImg}>
                                        <MDBIcon icon="save" className="mr-1"/> Agregar
                                    </MDBBtn>
                                </Fragment>
                                }
                            </div>
                            }   
                        <br/>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <div className="appBarSpacers"/>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol sm="10">
                                {this.state.lstImagenes.length>0 && 
                                    <MDBCard narrow className="white-text">
                                         <MDBCardHeader color="blue-gradient lighten-1"><MDBIcon icon="image" className="mr-1"/> Imagenes cargadas:</MDBCardHeader>
                                        <MDBCardBody className="text-center d-flex justify-content-center align-items-center flex-column">
                                            {this.state.lstImagenes.map(item =>
                                            <MDBCardText id={item}>{item}</MDBCardText>)}
                                        </MDBCardBody>
                                </MDBCard>
                                }
                        </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol sm="10" className="center">
                            <div className="text-center white-text">
                                {this.state.lstImagenes.length >0 && this.state.qr && 
                                <MDBBtn color="blue-grey" rounded onClick={this.Limpiar}>Nuevo  <MDBIcon icon="file-image" className="md-1" /></MDBBtn>
                                }
                                {this.state.statusMod &&
                                    <MDBBtn color="blue-grey" rounded onClick={this.Modificar}>Modificar <MDBIcon icon="edit" className="md-1" /></MDBBtn>
                                }
                            </div>
                        </MDBCol>
                </MDBRow>
            </MDBContainer>
            );
    }
}

export default CargarQR