import React, {Component} from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon, MDBAlert} from 'mdbreact';
import Controller from '../../controllers/LoginQR';

class LoginQR extends Component{

  constructor(props){
    super(props);
    this.state=({
      usuario:'', 
      contrasenia:'',
      token:'', 
      acceso:false,
      message:false,
    })
    this.submitHandler= this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.validacion = this.validacion.bind(this);
  }

  changeHandler = event => {this.setState({ [event.target.name]: event.target.value, message:false});};

  validacion(){
    if(this.state.usuario !=='' && this.state.contrasenia !== '') {return true}
    else{return false};
  }

  submitHandler = event => {
    event.preventDefault();
    if (this.validacion()){
      event.target.className += "was-validated";
      const data = Controller.fechDataUsuario(this.state.usuario, this.state.contrasenia);
      data.then(resp=>{
        if(resp.data.success){
          this.setState({acceso:true, token:resp.data.data})
          window.localStorage.setItem('Authorization', resp.data.data)
          window.location = '/LoadQR';
        }else
        {
          this.setState({acceso:false, message:true})
        }
      }).catch(err=>{
        console.log('Error', err);
      })
    }
  }

    render(){
        return(<MDBContainer md="10" className="row d-flex justify-content-center">
              <MDBRow className="center">
                <MDBCol md="10" className="center">
                <form className="needs-validation" onSubmit={this.submitHandler}>
                    <p className="h1 white-text text-center">
                      <strong>Generador </strong>
                      <a href='#!' className='blue-text font-weight-bold'>
                          <strong> QR </strong>
                      </a>
                      <strong> Nuevos Negocios </strong>
                      <MDBIcon fab icon="qrcode" className="blue-text ml-2 pr-2" />
                      </p>
                      {this.state.message && <MDBAlert sm="1" color="info">
                        <h4 className="alert-heading text-center">Acceso denegado!</h4>
                        <hr />
                        <p>Usuario y/o contraseña incorrectos, favor de verificar!</p>
                      </MDBAlert>}
                    <div className="cyan-text">
                      <MDBInput
                        name = "usuario"
                        label="Usuario"
                        icon="user"
                        group
                        type="text"
                        validate
                        required
                        error="wrong"
                        success="right"
                        className="white-text"
                        value={this.state.usuario} onChange={this.changeHandler}
                      />
                      <MDBInput
                        name = "contrasenia"
                        label="Type your password"
                        icon="lock"
                        group
                        required
                        type="password"
                        className="white-text"
                        validate
                        value={this.state.contrasenia} onChange={this.changeHandler}
                      />
                    </div>
                    <div className="text-center">
                      <MDBBtn type="submit">Login</MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
          </MDBContainer>)
    }
}


export default LoginQR;