import API from '../api/LoginQR';

const Controler = {
    fechDataUsuario: async(usuario, contrasenia)=>{
        return await API.getUsuario(usuario, contrasenia)
        .then(resp=>{
            return resp;
        }).catch(err=>{
            return err;
        }).finally(()=>{})
    },
}

export default Controler