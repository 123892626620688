import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import './App.css';
import ViewProduct from './components/ViewProduct';
import LoadQR from './components/LoadQR';
import LoginQR from './components/LoginQR';

function App() {
    return (
          <Router>
              {/* <Route path='/' exact component={ScannerQR}/> */}
              <Route path='/:codigo' exact component={ViewProduct}/>
              <Route path='/' exact component={LoginQR}/>
              <Route path='/LoadQR' exact component={LoadQR}/>
          </Router>
    );
}

export default App;
