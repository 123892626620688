import API from '../api/CodigoQR';

const Controler ={
    fechDataValorQR: async(codigo)=>{
        return await API.getValorQR(codigo)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            }).finally(()=>{
            
            })
    },
    fetchDataRegistrarQR: async(codigo, descripcion, urlweb, valor, tipo, valora, valorb, valorg, valorr, usuario)=>{
        return await API.postRegistroQR(codigo, descripcion, urlweb, valor, tipo, valora, valorb, valorg, valorr, usuario)
        .then(res=>{
            return res;
        }).catch(err=>{
            return err;
        }).finally(()=>{
        
        })
    },
    fetchDataAgregarImg: async(id, imagen, descripcion, notas)=>{
        return await API.postAgregarImg(id, imagen, descripcion, notas)
        .then(res=>{
            return res;
        }).catch(err=>{
            return err;
        }).finally(()=>{
        
        })
    },
    fetchDatauploadImg: async(formData)=>{
        return await API.postUploadImg(formData)
        .then(res=>{
            return res;
        }).catch(err=>{
            return err;
        }).finally(()=>{
        
        })
    },
    fetchDataActualizarQR: async(codigo, descripcion, url, tipo, valora, valorb, valorg, valorr, usuario)=>{
        return await API.putActualizarQR(codigo, descripcion, url, tipo, valora, valorb, valorg, valorr,usuario)
        .then(res=>{
            return res;
        }).catch(err=>{
            return err;
        }).finally(()=>{
        
        })
    },
    fetchDatauploadImgAPI: async(objfile)=>{
        return await API.postUploadImgAPI(objfile)
        .then(res=>{
            return res;
        }).catch(err=>{
            return err;
        }).finally(()=>{
        
        })
    }
}

export default Controler