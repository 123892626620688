import React, { Component, Fragment } from 'react';
import Bowser from "bowser";
import SlideItem from '../SliderItem/index';
import CaruselItem from '../CarouselItem/index';
import VerticalItem from '../VerticalItem/index';
import Controller from '../../controllers/Registro';
import ControllerCod from '../../controllers/PanelQR';
import Coverflow from 'react-coverflow';
import {MDBAnimation, MDBCarousel, MDBCarouselInner, MDBContainer} from "mdbreact";
import '../../styles/main.scss';

class ViewProduct extends Component {

  constructor(props){
    super(props)
    this.state = {
      codigo : this.props.location.pathname,
      lstImg :[], 
      response:true, 
      tipo:"0",
      ip: '',
      index: 0,
      item:1,
      vertical:true,
      titulo:"", 
      background: '241,112, 19, 1',
    }
    this.createChildren = this.createChildren.bind(this);
    this.createItem = this.createItem.bind(this);
    this.fechDataCodigo = this.fechDataCodigo.bind(this);
    this.fechDataIP = this.fechDataIP.bind(this);
  }
  
  componentWillMount(){
    const dispo = Bowser.parse(window.navigator.userAgent).platform.type ? Bowser.parse(window.navigator.userAgent).platform.type: 'N/O';
    const nav = Bowser.parse(window.navigator.userAgent).browser.name ? Bowser.parse(window.navigator.userAgent).browser.name: 'No se ejecutó';
    const verNav = Bowser.parse(window.navigator.userAgent).browser.version ? Bowser.parse(window.navigator.userAgent).browser.version: 'No se ejecutó';
    const os= Bowser.parse(window.navigator.userAgent).os.name ? Bowser.parse(window.navigator.userAgent).os.name:'N/O';
    const osver= Bowser.parse(window.navigator.userAgent).os.version ? Bowser.parse(window.navigator.userAgent).os.version:'N/O';
    const codigoNew = this.state.codigo.substr(1,this.state.codigo.length-1)
    setTimeout(()=>{
      const data = Controller.fetchDataRegistroLog(dispo, nav, verNav, this.state.ip, os, osver, codigoNew)
        data.then(res=>{   
        })
    }, 1200);
  }

  componentDidMount(){
    this.fechDataCodigo(this.state.codigo);
    this.fechDataIP();
  }

  fechDataCodigo(codigo){
    const codnew = codigo.substr(1,codigo.length-1);
    if(codnew && codnew !== 'LoadQR' && codnew !== 'ScannerQR'){
      const data = ControllerCod.fechDataCodigo(codnew)
      data.then(res=>{
        if(res.data.data.success === true){
          this.setState({lstImg: res.data.data.registro, response:true, tipo:res.data.data.registro[0].IdTipo, titulo:res.data.data.registro[0].Descripcion})
          window.screen.width <=800 ? this.setState({tipo:"3", vertical:true}): this.setState({vertical:false});
          document.body.style.background =`rgba(${ res.data.data.registro[0].ValorR}, ${ res.data.data.registro[0].ValorG }, ${ res.data.data.registro[0].ValorB }, ${ res.data.data.registro[0].ValorA })`;
        }
        else{
          this.setState({lstImg: [], response:false,tipo:0})
        }
      }).catch(err=>{
        this.setState({lstImg: [], response:false,tipo:0})
      })
    } else {this.setState({lstImg: [], response:false,tipo:0})};
  }

  fechDataIP(){
    const ip = Controller.fechDataIP()
    ip.then(res=>{
      this.setState({ip:res.data})
      return res.data;
    })
  }

  createChildren = () => this.state.lstImg.map(i => <SlideItem key={i.Idimg} imagen= {i} style={{ height: 100, background: '#fff' }}/>);

  createItem = () => this.state.lstImg.map(i => <CaruselItem key={i.Idimg} imagen= {i} id={i.id}/>);

  changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

  render() {
    return ( 
      <div>
        {this.state.tipo === "1" && this.state.response && this.state.lstImg.length>0 && this.state.vertical=== false && 
          <Fragment>
            <MDBAnimation type="bounce">
                <h5 className="display-4 font-weight-bold w-responsive mx-auto titulo">
                  {this.state.titulo}
                </h5>
            </MDBAnimation>
              <Coverflow
                displayQuantityOfSide={2}
                navigation={false}
                max-width={960}
                max-height={500}
                enableScroll={false}
                enableHeading={true}
                clickable={true}
                active={0}
                media={{
                  '@media (max-width: 960px)': {
                    width: '600px',
                    height: '300px'
                  },
                  '@media (min-width: 900px)': {
                    width: '960px',
                    height: '600px'
                  }
                  }}
              >
                {this.createChildren()}
              </Coverflow>
          </Fragment>}
        {this.state.response && this.state.lstImg.length>0 && this.state.tipo === "2" && this.state.vertical=== false && 
          <Fragment>
            <MDBAnimation type="bounce">
              <h5 className="display-4 font-weight-bold w-responsive mx-auto titulo">
                  {this.state.titulo}
              </h5>
            </MDBAnimation>
            <MDBContainer>
              <MDBCarousel
                activeItem={this.state.item}
                length={this.state.lstImg.length}
                showControls={true}
                showIndicators={true}
              >
                <MDBCarouselInner>
                  {this.createItem()}
                </MDBCarouselInner>
              </MDBCarousel>
          </MDBContainer>
        </Fragment>
        }
        {this.state.response && this.state.lstImg.length>0 && this.state.tipo === "3" && this.state.vertical=== true &&
          <Fragment>
              <br/>
              <MDBAnimation type="bounce">
                <h3 className="display-5 font-weight-bold w-responsive mx-auto titulo" >
                  {this.state.titulo}
                </h3>
              </MDBAnimation>
              <VerticalItem images= {this.state.lstImg} noMargins/>
          </Fragment>
        }
      </div>
    );  
  }
} 

export default ViewProduct