import React, {Component, Fragment} from 'react';
import { MDBCarouselCaption, MDBCarouselItem, MDBView, MDBMask} from "mdbreact";
import urlImg from '../../api/url';

class CarouselItem extends Component{
    render(){
        const {original, DescImg, id, Urlqr} = this.props.imagen;
        return(
          <Fragment>
            <MDBCarouselItem itemId={id} className="img-fluid">
              <MDBView hover>
                <img
                  className="d-block card"
                  src={`${urlImg.pathImg}${original}`}
                  alt="Second slide"
                />
                <MDBMask overlay="black-strong" className="flex-center" />
              </MDBView>
              <MDBCarouselCaption>
                <h3 className="display-6 font-weight-bold "><a href={Urlqr} target="_blank">{DescImg}</a></h3>
              </MDBCarouselCaption>
            </MDBCarouselItem>
          </Fragment>
        )
    }
}

export default CarouselItem