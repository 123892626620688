import axios from 'axios';
import url from './url';

const Api = {
    postRegistroLog: async (dispositivo, navegador, version, ip, os, osver, codigo) => {   
        var data = {
            'dispositivo': dispositivo,
            'navegador': navegador,
            'version': version,
            'ip': ip,
            'os': os,
            'osver': osver, 
            'codigo':codigo
        }
        return await axios.post(url.url+'/registo',
            data,
            {headers: {
                        'Content-Type': 'application/json'                       
                    }
            }
        ) 
    },
    getIP: async()=>{
        const urlMe = 'https://api.ipify.org'
        var opt = {
            method :'GET',
            mode : 'cors',
            cache : 'default', 
            headers :{
                'Content-Type': 'application/json'
            }
        }
        return await axios.get(urlMe, opt)
    }
}
export default Api