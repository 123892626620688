import React, {Component} from "react";
import { MDBContainer, MDBRow, MDBCol} from "mdbreact";
import Lightbox from "react-image-lightbox";
import urlImg from '../../api/url';
import "./Lightbox.css";

class LightboxItem extends Component {
state = {
  photoIndex: 0,
  isOpen: false, 
  images: this.props.images,
}

renderImages = () => {
  
return this.state.images.map(imageSrc => {
  const privateKey = imageSrc.id-1;
  const url =imageSrc.Urlqr
  return (
    <MDBCol md="1" key={imageSrc.id-1}>
      <figure>
        <img src={`${urlImg.pathImg}${imageSrc.original}`} alt="Gallery" className="img-fluid" onClick={()=>
        this.setState({ photoIndex: privateKey, isOpen: true })
        }
        />
        <h5 className="display-6 font-weight-bold"><a href={url !== '' ? url : "#!"} target="_blank"><p>{imageSrc.DescImg}</p></a></h5>
      </figure>
    </MDBCol>
    );
  })
}

render() {
const { photoIndex, isOpen, images } = this.state;
  return (
      <MDBContainer className="mt-5">
        <div className="mdb-lightbox">
          <MDBRow className="d-flex align-items-center">
            {this.renderImages()}
          </MDBRow>
        </div>
        {isOpen && (
        <Lightbox
          mainSrc={`${urlImg.pathImg}${images[photoIndex].original}`}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          imageTitle={photoIndex + 1 + "/" + images.length}
          onCloseRequest={() => this.setState({ isOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length
            })
            }
          />
        )}
      </MDBContainer>
    );
  }
}

export default LightboxItem