import axios from 'axios';
import url from './url';

const Api = {
    getCodigoQR: async (codigo) => {   
        var urlMe = url.url + '/' + codigo;
        var opts = {
            method :'GET',
            mode : 'cors',
            cache : 'default', 
            headers :{
                'Content-Type': 'application/json'
            }
        }
        return await axios.get(urlMe, opts)
    }
}

export default Api