/**
 * @description Controlador del Login.
 * se pasan como parámetros el usuario, el password y el país de selección.
*/
import API from '../api/Registro';

const Controller = {
    fetchDataRegistroLog: async (dispositivo, navegador, version, ip, os, osver, codigo) =>{
        return await API.postRegistroLog(dispositivo, navegador, version, ip, os, osver, codigo)
        .then(resp => {
            return resp;
        })
        .catch(err =>{
            return err;
        })
    }
    , fechDataIP : async()=>{
        return await API.getIP()
        .then(resp=>{
            return resp;
        })
        .catch(err=>{
            return err;
        })
    }
}

export default Controller