import React, { Component } from 'react';
import urlImg from '../../api/url';

class Producto extends Component{

    render(){
        const {original, DescImg, Urlqr} = this.props.imagen;
        return (
            <figure>
                <img src={`${urlImg.pathImg}${original}`} alt={DescImg} data-action={Urlqr} style={{ display: 'block', width: '100%' }}/>    
                {DescImg !=='' && <figcaption className="figure-caption text-right white-text">
                    <h6 className="display-6 font-weight-bold"><a href={Urlqr!== '' ? Urlqr : "#!"} target="_blank"><p>{DescImg}</p></a></h6>
                </figcaption>}
            </figure>

        )
    }
}

export default Producto;