import API from '../api/PanelQR';

const Controler ={
    fechDataCodigo: async(codigo)=>{
    return await API.getCodigoQR(codigo)
        .then(resp=>{
            return resp;
        }).catch(err=>{
            return err;
        }).finally(()=>{
        
        })
    }
}

export default Controler